const Partners = () => {
  return (
    <section className="py-10">
      <div className="container mx-auto grid grid-cols-12 gap-6 px-6">
        <div className="col-span-12">
          <div className="text-center uppercase text-primary text-sm">
            Бидний түнш
          </div>
          <div className="text-center capitalize font-semibold text-2xl">
            Хамтрагчид
          </div>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-9 gap-6 col-span-12">
          <div className="border border-transparent hover:border-stone-100 cursor-pointer p-6 rounded-xl group transition-all">
            <img className="aspect-square object-cover group-hover:scale-105 transition-all" src="https://cdn.britannica.com/94/193794-050-0FB7060D/Adidas-logo.jpg"></img>
            <div className="text-center group-hover:font-bold transition-all">
              Adidas
            </div>
          </div>
          <div className="border border-transparent hover:border-stone-100 cursor-pointer p-6 rounded-xl group transition-all">
            <img className="aspect-square object-cover group-hover:scale-105 transition-all" src="https://cdn.britannica.com/94/193794-050-0FB7060D/Adidas-logo.jpg"></img>
            <div className="text-center group-hover:font-bold transition-all">
              Adidas
            </div>
          </div>
          <div className="border border-transparent hover:border-stone-100 cursor-pointer p-6 rounded-xl group transition-all">
            <img className="aspect-square object-cover group-hover:scale-105 transition-all" src="https://cdn.britannica.com/94/193794-050-0FB7060D/Adidas-logo.jpg"></img>
            <div className="text-center group-hover:font-bold transition-all">
              Adidas
            </div>
          </div>
          <div className="border border-transparent hover:border-stone-100 cursor-pointer p-6 rounded-xl group transition-all">
            <img className="aspect-square object-cover group-hover:scale-105 transition-all" src="https://cdn.britannica.com/94/193794-050-0FB7060D/Adidas-logo.jpg"></img>
            <div className="text-center group-hover:font-bold transition-all">
              Adidas
            </div>
          </div>
          <div className="border border-transparent hover:border-stone-100 cursor-pointer p-6 rounded-xl group transition-all">
            <img className="aspect-square object-cover group-hover:scale-105 transition-all" src="https://cdn.britannica.com/94/193794-050-0FB7060D/Adidas-logo.jpg"></img>
            <div className="text-center group-hover:font-bold transition-all">
              Adidas
            </div>
          </div>
          <div className="border border-transparent hover:border-stone-100 cursor-pointer p-6 rounded-xl group transition-all">
            <img className="aspect-square object-cover group-hover:scale-105 transition-all" src="https://cdn.britannica.com/94/193794-050-0FB7060D/Adidas-logo.jpg"></img>
            <div className="text-center group-hover:font-bold transition-all">
              Adidas
            </div>
          </div>
          <div className="border border-transparent hover:border-stone-100 cursor-pointer p-6 rounded-xl group transition-all">
            <img className="aspect-square object-cover group-hover:scale-105 transition-all" src="https://cdn.britannica.com/94/193794-050-0FB7060D/Adidas-logo.jpg"></img>
            <div className="text-center group-hover:font-bold transition-all">
              Adidas
            </div>
          </div>
          <div className="border border-transparent hover:border-stone-100 cursor-pointer p-6 rounded-xl group transition-all">
            <img className="aspect-square object-cover group-hover:scale-105 transition-all" src="https://cdn.britannica.com/94/193794-050-0FB7060D/Adidas-logo.jpg"></img>
            <div className="text-center group-hover:font-bold transition-all">
              Adidas
            </div>
          </div>
          <div className="border border-transparent hover:border-stone-100 cursor-pointer p-6 rounded-xl group transition-all">
            <img className="aspect-square object-cover group-hover:scale-105 transition-all" src="https://cdn.britannica.com/94/193794-050-0FB7060D/Adidas-logo.jpg"></img>
            <div className="text-center group-hover:font-bold transition-all">
              Adidas
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Partners