import React from 'react'
import { AiOutlineClose } from 'react-icons/ai'

function BurgerMenu({showMenu, children, onClose}) {
  return (
    <div className={`fixed w-[300px] z-[100] inset-y-0 bg-white top-0 transition-all ease-in duration-150 right-0 shadow-menuShadow ${showMenu ? 'translate-x-0' : 'translate-x-full'}`}>
      <div className='py-3 flex justify-end px-4'>
        <button className='p-2 border border-transparent text-primary hover:border-primary transition-all rounded-full' onClick={onClose}>
          <AiOutlineClose size={24}/>
        </button>
      </div>
      {children}
    </div>
  )
}

export default BurgerMenu