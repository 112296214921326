import { useParams } from "react-router-dom"

const NewsDetail = () => {
  const { id } = useParams()
  
  return (
    <div>
      <section className="py-10 bg-primary">
        <div className="container mx-auto px-6">
          <div className="font-bold text-xl text-white">
            Мэдээ мэдээлэл
          </div>
        </div>
      </section>
      <div className="py-10">
        <div className="container mx-auto p-6 bg-white rounded-xl shadow-md">
          <div dangerouslySetInnerHTML={{__html: `<div class="inews" data-controller="news" data-action="photoslide@window->news#photoslide" data-async-target="async" data-async="news_controller">
        
        <h1>
  ПАРИС 2024: Ё.Басхүү дагах барилдаан хийнэ
</h1>
<div class="iauthor">
  <div class="name">
    Э.Тэмүүлэн, iKon.mn
  </div>
  <div class="time ikon-refresh-timefromnow" rawdate="07/28/2024 19:29:00"><span>32 минутын өмнө</span></div>
  <div class="socialtopbar">
    <div class="social_share" data-async-target="fbsdk" data-controller="socialshare" data-socialshare-url-value="https://www.ikon.mn/n/378w" data-socialshare-text-value="%D0%9F%D0%90%D0%A0%D0%98%D0%A1%202024%3A%20%D0%81.%D0%91%D0%B0%D1%81%D1%85%D2%AF%D2%AF%20%D0%B4%D0%B0%D0%B3%D0%B0%D1%85%20%D0%B1%D0%B0%D1%80%D0%B8%D0%BB%D0%B4%D0%B0%D0%B0%D0%BD%20%D1%85%D0%B8%D0%B9%D0%BD%D1%8D">
  <div class="fb-like fb_iframe_widget" data-href="https://ikon.mn/n/378w" data-width="" data-layout="button_count" data-action="like" data-size="large" data-share="true" fb-xfbml-state="rendered" fb-iframe-plugin-query="action=like&amp;app_id=1410230139213728&amp;container_width=280&amp;href=https%3A%2F%2Fikon.mn%2Fn%2F378w&amp;layout=button_count&amp;locale=en_GB&amp;sdk=joey&amp;share=true&amp;size=large&amp;width="><span style="vertical-align: bottom; width: 174px; height: 28px;"><iframe name="fd90c646db40aebdc" width="1000px" height="1000px" data-testid="fb:like Facebook Social Plugin" title="fb:like Facebook Social Plugin" frameborder="0" allowtransparency="true" allowfullscreen="true" scrolling="no" allow="encrypted-media" src="https://www.facebook.com/v19.0/plugins/like.php?action=like&amp;app_id=1410230139213728&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df75c45de4735a1ac0%26domain%3Dikon.mn%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Fikon.mn%252Ffa4f878cdae30a15b%26relation%3Dparent.parent&amp;container_width=280&amp;href=https%3A%2F%2Fikon.mn%2Fn%2F378w&amp;layout=button_count&amp;locale=en_GB&amp;sdk=joey&amp;share=true&amp;size=large&amp;width=" style="border: none; visibility: visible; width: 174px; height: 28px;" class=""></iframe></span></div>
  <div class="ikon-share-twitter2" data-action="click->socialshare#tweet">
    <div class="logo">
      <svg viewBox="0 0 24 24" aria-hidden="true" style="width: 16px; display: inline-block;margin:-5px 4px 0px -5px;">
        <g>
          <path style="fill:#ffffff;" d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z">
          </path>
        </g>
      </svg>
    </div>
    Post
  </div>
</div>

  </div>
</div>
<div class="itype">
  IKON.MN
</div>

        <div class="icontent">
          
    <div class="ikonmedia margin-bottom16 medialoaded" da="zoom">
      <img alt="Зураг" src="https://content.ikon.mn/news/2024/7/28/m1cjpy_450181669_472590462378630_6093753217270084810_n_ph.jpg" class="thumb" style="opacity: 0;">
      <img alt="зураг" class="ikonlazy loaded" data-src="https://content.ikon.mn/news/2024/7/28/m1cjpy_450181669_472590462378630_6093753217270084810_n_x974.jpg" data-action="zoom" src="https://content.ikon.mn/news/2024/7/28/m1cjpy_450181669_472590462378630_6093753217270084810_n_x974.jpg">
      <div class="ikonarsave" style="padding-bottom: 66.5%; background-image: url(&quot;https://content.ikon.mn/news/2024/7/28/m1cjpy_450181669_472590462378630_6093753217270084810_n_ph.jpg&quot;);"></div>
    </div>


  <div class="livenewscontrol">
  <i class="hide480">эрэмбэлэх:&nbsp;&nbsp;</i>
  <span data-action="click->news#order_desc" data-news-target="desc" class="active">шинэ нь эхэнд</span>
  <span data-action="click->news#order_asc" data-news-target="asc">хуучин нь эхэнд</span>
</div>
<div data-news-target="ldesc">
    <div class="livenews">
  <div class="livenewsdate">
    <p>
      <strong>19:29</strong> <span>2024/07/28</span>
    </p>
  </div>
  <div class="livenewscontent">
    <p>Ё.Басхүү хэсгийн аваргын төлөө Бразилын&nbsp;Уильян Лимад ялагдсанаар дагах тойрогт шилжлээ. Тэрбээр Францын&nbsp;Валид Хярыг дагах барилдаанд ялбал хүрэл медалийн төлөө барилдах эрхтэй болно.</p>

<p>Финалын барилдаанууд 22:00 цагаас үргэлжлэх юм.</p>

  </div>
  <div class="clear">
  </div>
</div>

    <div class="livenews">
  <div class="livenewsdate">
    <p>
      <strong>18:34</strong> <span>2024/07/28</span>
    </p>
  </div>
  <div class="livenewscontent">
    <p>Эрэгтэйчүүдийн 66 кг-д эхний даваанд хийлсэн Ё.Басхүү хоёрдугаар тойрогт Украины&nbsp;Иадов&nbsp;Богданыг цэвэр ялснаар шөвгийн наймд шалгарлаа. Манай жүдоч хэсгийн аваргын төлөө Бразилын Уильян Лима, Туркменистаны Сердар Рахимов хоёрын хэн ялсантай нь хүч үзнэ.&nbsp;</p>

  </div>
  <div class="clear">
  </div>
</div>

    <div class="livenews">
  <div class="livenewsdate">
    <p>
      <strong>18:21</strong> <span>2024/07/28</span>
    </p>
  </div>
  <div class="livenewscontent">
    <p>Эмэгтэйчүүдийн 52 кг-д өрсөлдсөн Л.Сосорбарам Унгарын Река Паппд ялагдлаа. Манай жүдоч гурван торгуулиар хасагдсан юм.</p>

  </div>
  <div class="clear">
  </div>
</div>

</div>
<div class="livenews">
  <div class="livenewsdate">
    <p>
      <strong>17:22</strong> <span>2024/07/28</span>
    </p>
  </div>
  <div class="livenewscontent">
    <p>Парисын олимпын эхний медалийг эх орондоо авчирсан жүдогийн төрөлд өнөөдөр эмэгтэйчүүдийн 52 кг-д Л.Сосорбарам, эрэгтэйчүүдийн 66 кг-д Ё.Басхүү нар өрсөлдөнө.</p>

<p>Эмэгтэйчүүдийн 52 кг-д Баянхонгор аймгийн Эрдэнэцогт сумын харьяат, "Алдар" спорт хорооны тамирчин Л.Сосорбарам эхний даваанд Испанийн Торо Солерыг даралтаар буулгаж авлаа.&nbsp;</p>

<p>Ингэснээр манай тамирчин шөвгийн 16-д Унгарын Река Папптай учраа таарчээ.</p>

<p>Мөн өнөөдөр эрэгтэйчүүдийн 66 кг-д Завхан аймгийн Нөмрөг сумын харьяат, "Хилчин" спорт хорооны тамирчин Ё.Басхүү өрсөлдөх билээ.&nbsp;</p>

<p>Эхний тойрогт хийлсэн Ё.Басхүүгийн хоёрын давааны өрсөлдөгчөөр Украины Иадов&nbsp;Богдан&nbsp;байх юм.</p>

  </div>
  <div class="clear">
  </div>
</div>

<div data-news-target="lasc" class="ikhidden">
    <div class="livenews">
	<div class="livenewsdate">
		<p>
		   <strong>18:21</strong> <span>2024/07/28</span>
	 	</p>
	</div>
	<div class="livenewscontent">
		<p>Эмэгтэйчүүдийн 52 кг-д өрсөлдсөн Л.Сосорбарам Унгарын Река Паппд ялагдлаа. Манай жүдоч гурван торгуулиар хасагдсан юм.</p>

	</div>
  <div class="clear">
  </div>
</div>
    <div class="livenews">
	<div class="livenewsdate">
		<p>
		   <strong>18:34</strong> <span>2024/07/28</span>
	 	</p>
	</div>
	<div class="livenewscontent">
		<p>Эрэгтэйчүүдийн 66 кг-д эхний даваанд хийлсэн Ё.Басхүү хоёрдугаар тойрогт Украины&nbsp;Иадов&nbsp;Богданыг цэвэр ялснаар шөвгийн наймд шалгарлаа. Манай жүдоч хэсгийн аваргын төлөө Бразилын Уильян Лима, Туркменистаны Сердар Рахимов хоёрын хэн ялсантай нь хүч үзнэ.&nbsp;</p>

	</div>
  <div class="clear">
  </div>
</div>
    <div class="livenews">
	<div class="livenewsdate">
		<p>
		   <strong>19:29</strong> <span>2024/07/28</span>
	 	</p>
	</div>
	<div class="livenewscontent">
		<p>Ё.Басхүү хэсгийн аваргын төлөө Бразилын&nbsp;Уильян Лимад ялагдсанаар дагах тойрогт шилжлээ. Тэрбээр Францын&nbsp;Валид Хярыг дагах барилдаанд ялбал хүрэл медалийн төлөө барилдах эрхтэй болно.</p>

<p>Финалын барилдаанууд 22:00 цагаас үргэлжлэх юм.</p>

	</div>
  <div class="clear">
  </div>
</div>
</div>



<div class="rounded-md bg-yellow-50 p-4 fira-sans" id="copyright">
  <div class="flex">
    <div class="flex-shrink-0">
      <svg class="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path>
      </svg>
    </div>
    <div class="ml-3">
      <div class="text-sm font-medium text-yellow-800">Анхааруулга</div>
      <div class="mt-2 text-sm text-yellow-700">
        <p class="italic">Хэвлэл мэдээллийн байгууллагууд (Телевиз, Радио, Social болон Вэб хуудаснууд) манай мэдээллийг аливаа хэлбэрээр бүрэн ба хэсэгчлэн авч ашиглахдаа эх сурвалжаа (ikon.mn) заавал дурдах ёстойг анхаарна уу!</p>
      </div>
    </div>
  </div>
</div>

        </div>
      </div>`}}>

          </div>
        </div>
      </div>
    </div>
  )
}

export default NewsDetail