import { IoCheckmarkDone } from "react-icons/io5";
import { GrCertificate } from "react-icons/gr";

const AboutUs = () => {
  return (
    <section className="py-10">
      <div className="container mx-auto grid grid-cols-12 gap-6 px-6">
        <div className="col-span-12 md:col-span-6">
          <div className="text-center uppercase text-primary text-sm">
            Товч танилцуулага
          </div>
          <div className="text-center capitalize font-semibold text-2xl mb-10">
            Бидний тухай
          </div>
          <div className="mb-3">
            Ullamco aliqua eu dolore amet pariatur pariatur nostrud et. Esse nostrud deserunt anim officia labore culpa voluptate aliquip sint. Ut laboris aliquip minim ipsum tempor consectetur eiusmod ut. Dolore amet ex velit esse eu commodo.
          </div>
          <div className="flex flex-col gap-3">
            <div className="flex gap-3">
              <IoCheckmarkDone className="text-3xl text-primary"/>
              Эмнэлэг
            </div>
            <div className="flex gap-3">
              <IoCheckmarkDone className="text-3xl text-primary"/>
              Diagnostic Centers
            </div>
            <div className="flex gap-3">
              <IoCheckmarkDone className="text-3xl text-primary"/>
              Individual Radiologists
            </div>
            <div className="flex gap-3">
              <IoCheckmarkDone className="text-3xl text-primary"/>
              Governments
            </div>
            <div className="flex gap-3">
              <IoCheckmarkDone className="text-3xl text-primary"/>
              WHO, health organizations
            </div>
            <div className="flex gap-3">
              <IoCheckmarkDone className="text-3xl text-primary"/>
              NGOs and Public Private Partnership firms
            </div>
          </div>
        </div>
        <div className="col-span-12 md:col-span-6 flex flex-col gap-6">
          <div className="bg-white shadow rounded flex items-center p-6 gap-3">
            <div className="rounded-full p-6 border border-primary">
              <GrCertificate className="text-[20px] md:text-[40px] text-primary"/>
            </div>
            <div className="flex-1">
              <div className="font-bold text-xl md:text-2xl mb-3">Experienced Radiologist</div>
              <div>
              Its a medical professional who specializes in interpreting and diagnosing medical images.
              </div>
            </div>
          </div>
          <div className="bg-white shadow rounded flex items-center p-6 gap-3">
            <div className="rounded-full p-6 border border-primary">
              <GrCertificate className="text-[20px] md:text-[40px] text-primary"/>
            </div>
            <div className="flex-1">
              <div className="font-bold text-xl md:text-2xl mb-3">Accurate Reports</div>
              <div>
              Teleradiology providers should have systems in place for quality assurance to ensure that reports are accurate and consistent.
              </div>
            </div>
          </div>
          <div className="bg-white shadow rounded flex items-center p-6 gap-3">
            <div className="rounded-full p-6 border border-primary">
              <GrCertificate className="text-[20px] md:text-[40px] text-primary"/>
            </div>
            <div className="flex-1">
              <div className="font-bold text-xl md:text-2xl mb-3">Efficient Turn-Around Time (T.A.T.)</div>
              <div>
              Particularly important in teleradiology, as timely delivery of diagnostic reports.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutUs