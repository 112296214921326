import AboutUs from "./components/AboutUs"
import Carousel from "./components/Carousel"
import JoinUs from "./components/JoinUs"
import News from "./components/News"
import Partners from "./components/Partners"
import Products from "./components/Products"
import Services from "./components/Services"

const Home = () => {
  return (
    <div>
      <Carousel>

      </Carousel>
      <AboutUs>

      </AboutUs>
      <Services>

      </Services>
      <JoinUs>

      </JoinUs>
      <Products>
        
      </Products>
      {/* <News>
        
      </News> */}
      <Partners>
        
      </Partners>
    </div>
  )
}

export default Home