import React, { useContext, useEffect, useState } from "react";
import logo from "assets/images/logo.png";

import { Link, NavLink } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import BurgerMenu from "./BurgerMenu";

const headerMenu = [
  {
    label: "Нүүр",
    path: "/",
    form: "home01",
  },
  {
    label: "Бүтээгдэхүүн",
    path: "/products",
    form: "product01",
  },
  {
    label: "Бидний тухай",
    path: "/about",
    form: "tuition01",
  },
  {
    label: "Үйлчилгээ",
    path: "/service",
    form: "lesson01",
  },
];

function Header() {
  const [showBurgerMenu, setShowBurgerMenu] = useState(false);
  // const { action } = useContext(AuthContext)
  // const { state } = useContext(AuthContext);
  // const roleId = state.studentInfo?.profile?.roleId;

  const handleClickBurgerMenu = (e) => {
    e.preventDefault();
    setShowBurgerMenu((prev) => !prev);
  };
  return (
     <header className="bg-white sticky top-0 border-b shadow-sm  z-10">
      <div
        className={
          "inset-x-0 h-[60px]  top-0 flex justify-between px-7 py-2 transition-all"
        }
      >
        <NavLink to={"/"}>
          <img src={logo} style={{ height: 40 }} />
        </NavLink>
        <div className="items-center gap-4 text-black hidden md:flex">
          {headerMenu.map((menu, i) => (
            <NavLink
              key={i}
              to={menu.path}
              className={({ isActive }) =>
                `menu-item px-3 transition-all rounded ${
                  isActive ? "bg-primary text-white" : ""
                }`
              }
            >
              {menu.label}
            </NavLink>
          ))}
        </div>
        <div className="md:hidden block">
          <button
            className="p-2 border border-transparent text-primary hover:border-primary transition-all rounded-full"
            onClick={handleClickBurgerMenu}
          >
            <GiHamburgerMenu size={24} />
          </button>
        </div>
      </div>
      <BurgerMenu showMenu={showBurgerMenu} onClose={handleClickBurgerMenu}>
        <div className="flex flex-col divide-y">
          {headerMenu.map((menu, i) => (
            <NavLink
              key={i}
              to={menu.path}
              className={({ isActive }) =>
                `menu-item px-7 py-2 transition-all rounded ${
                  isActive ? "bg-primary text-white" : ""
                }`
              }
            >
              {menu.label}
            </NavLink>
          ))}
          {/* <button className='flex items-center gap-2 py-2 px-7 text-red-400' onClick={() => action.logout()}>
            <FiLogOut size={18}/> ГАРАХ
          </button> */}
        </div>
      </BurgerMenu>
    </header>
  );
}

export default Header;
