import { Splide, SplideSlide } from "@splidejs/react-splide"
import Card from "components/Card"
import { useState } from "react"
import { Link } from "react-router-dom"

const Products = () => {
  const [ data, setData ] = useState(new Array(10).fill({
    type1: '',
    type2: '',
    image: 'https://images.pexels.com/photos/1413412/pexels-photo-1413412.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    traveledNumbers: 30,
    title: 'Мотоцикл',
    rating: '5/5',

  }))
  
  return (
    <div className="container mx-auto grid grid-cols-12 gap-6 px-6">
      <div className="col-span-12 md:col-span-12">
        <div className="mb-10">
          <div className="text-center uppercase text-primary text-sm">
            Санал болгох
          </div>
          <div className="text-center capitalize font-semibold text-2xl">
            Бараа бүтээгдэхүүн
          </div>
        </div>
        <Splide
          options={{
            rewind: true,
            pagination: false,
            perPage: 4,
            perMove: 1,
            gap: 16,
            breakpoints: {
              768: {
                perPage: 1,
              },
              1024: {
                perPage: 3,
              },
            },
          }}
          className="px-16 -mx-16"
          aria-label="travel-carousel"
        >
          {data.map((foo, fooIndex) => {
            return (
              <SplideSlide className="relative" key={`travel-${fooIndex}`}>
                <Card data={foo} index={fooIndex}></Card>
              </SplideSlide>
            );
          })}
        </Splide>
        <div className="text-center mt-8">
          <Link
            to="/products"
            className="text-secondary font-normal px-20 py-2 rounded-lg border"
          >
            Бусад
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Products