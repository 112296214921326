const JoinUs = () => {
  return (
    <section className="py-10 bg-stone-200">
      <div className="flex flex-col justify-center items-center px-6">
        <div className="text-center font-bold text-2xl mb-10">
          Connect with confidence: <br/>
          Contact our trusted Teleradiology team today.
        </div>
        <button className="bg-primary text-white py-3 px-10 rounded-2xl hover:bg-primary/90">
          Join As Teleradiologists
        </button>
      </div>
    </section>
  )
}

export default JoinUs