import React, { useEffect, useContext, useState } from "react";
import home from "assets/images/rcmMongolia.jpg";
import vertical from "assets/images/rcmMongolia.jpg";
import { useWindowSize } from "react-use";
import { useNavigate } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import slide1 from "../../../assets/images/slide1.jpg";
import slide2 from "../../../assets/images/slide2.jpg";
const Carousel = () => {
  const { width, height } = useWindowSize();

  const [loading, setLoading] = useState(false);
  return (
    <section>
      <div
        id="home-section"
        className="w-full sm:h-screen flex justify-center items-center overflow-x-hidden bg-scroll sm:bg-fixed"
        style={{
          background: `url(${width < height ? vertical : home})`,
          backgroundPosition: "center",
          height: "100vh",
          backgroundSize: "cover",
        }}
      >
        <div className="max-w-[1280px] px-10 sm:px-24 xl:px-0 text-white sm:block ">
          <h1 className="text-[24pt] sm:text-[40pt] md:text-[60pt] xl:text-[80pt] font-extrabold leading-none drop-shadow">
            ТУН УДАХГҮЙ!
          </h1>
        </div>
      </div>
      {/* <Splide
        ariaLabel="carousel"
        options={{
          type: "loop",
          autoplay: "pause",
          perPage: 1,
          height: "50vh",
          width: "100vw",
        }}
      >
        <SplideSlide>
          <div
            className="h-full bg-no-repeat bg-right flex items-center relative"
            style={{
              backgroundImage: `url(${slide1})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="absolute inset-0 bg-black-gradient"></div>
            <div className="container mx-auto z-10 px-6 bg-black bg-opacity-50 p-6 rounded-md">
              <div className="text-primary font-bold text-3xl">
                RCM Mongolia
              </div>
              <div className="text-white font-medium mt-3 mb-10">
                Цацрагийн хамгаалалт, аюулгүй байдлын цогц үйлчилгээг
              </div>
              <button className="bg-primary py-2 px-10 rounded text-white font-medium">
                Example
              </button>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div
            className="h-full bg-no-repeat bg-right flex items-center relative"
            style={{
              backgroundImage: `url(${slide2})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="absolute inset-0 bg-black-gradient"></div>
            <div className="container mx-auto bg-black bg-opacity-50 z-10 px-6">
              <div className="text-primary font-bold text-3xl">
                RCM Mongolia
              </div>
              <div className="text-white font-medium mt-3 mb-10">
                Цацрагийн хамгаалалт, аюулгүй байдлын цогц үйлчилгээг
              </div>
              <button className="bg-primary py-2 px-10 rounded text-white font-medium">
                Example
              </button>
            </div>
          </div>
        </SplideSlide>
      </Splide> */}
    </section>
  );
};

export default Carousel;
