import { IoCheckmarkDone } from "react-icons/io5"

const Services = () => {
  return (
    <section className="pt-10 pb-20">
      <div className="container mx-auto grid grid-cols-12 gap-6 px-6">
        <div className="col-span-12 mb-10">
          <div className="text-center uppercase text-primary text-sm">
            Сонгох шалтгаан
          </div>
          <div className="text-center capitalize font-semibold text-2xl mb-10">
            Үйлчилгээнүүд
          </div>
        </div>
        <div className="col-span-12 md:col-span-6 mb-10 md:mb-0 max-w-[80vw]">
          <div className="grid grid-cols-2 gap-6 rotate-45">
            <div className="rotated-image flex justify-end items-end">
              <div className="w-[150px] max-w-[30vw] aspect-square relative overflow-hidden">
                <img className="absolute w-full h-full object-cover -rotate-45" src='https://images.pexels.com/photos/5750777/pexels-photo-5750777.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'></img>
              </div>
            </div>
            <div className="rotated-image flex items-end">
              <div className="w-[150px] max-w-[30vw] aspect-square relative overflow-hidden">
                <img className="absolute w-full h-full object-cover -rotate-45" src='https://images.pexels.com/photos/5750777/pexels-photo-5750777.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'></img>
              </div>
            </div>
            <div className="rotated-image flex justify-end items-start">
              <div className="w-[150px] max-w-[30vw] aspect-square relative overflow-hidden">
                <img className="absolute w-full h-full object-cover -rotate-45" src='https://images.pexels.com/photos/5750777/pexels-photo-5750777.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'></img>
              </div>
            </div>
            <div className="rotated-image flex justify-start items-start">
              <div className="w-[150px] max-w-[30vw] aspect-square relative overflow-hidden">
                <img className="absolute w-full h-full object-cover -rotate-45" src='https://images.pexels.com/photos/5750777/pexels-photo-5750777.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'></img>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-12 md:col-span-6">
          <div className="flex flex-col gap-3">
            <div className="flex gap-3">
              <IoCheckmarkDone className="text-3xl text-primary"/>
              Эмнэлэг
            </div>
            <div className="flex gap-3">
              <IoCheckmarkDone className="text-3xl text-primary"/>
              Diagnostic Centers
            </div>
            <div className="flex gap-3">
              <IoCheckmarkDone className="text-3xl text-primary"/>
              Individual Radiologists
            </div>
            <div className="flex gap-3">
              <IoCheckmarkDone className="text-3xl text-primary"/>
              Governments
            </div>
            <div className="flex gap-3">
              <IoCheckmarkDone className="text-3xl text-primary"/>
              WHO, health organizations
            </div>
            <div className="flex gap-3">
              <IoCheckmarkDone className="text-3xl text-primary"/>
              NGOs and Public Private Partnership firms
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Services