import { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { TextPlugin } from "gsap/TextPlugin";
import { useGSAP } from '@gsap/react';

const AboutUs = () => {
  const main = useRef()

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger, useGSAP, MotionPathPlugin, TextPlugin);
  }, [])
  
  useGSAP(() => {
    gsap.fromTo("#introduction", {
      opacity: 0
    }, {
      opacity: 1, 
      duration: 1
    })

    gsap.utils.toArray("#title").forEach((title, index) => {
      gsap.fromTo(title, {
        opacity: 0
      }, {
        ease: "none",
        opacity: 1,
        scrollTrigger: {
          trigger: title,
          scrub: 1,
          start: "top center",
          end: "bottom center"
        }
      })
    })

    gsap.defaults({ease: "none"});

    gsap.utils.toArray('#section').forEach((section, index) => {
      const w = section.querySelector('.image-wrapper');
      const [x, xEnd] = (index % 2) ? ['100%', (w.scrollWidth - section.offsetWidth) * -1] : [w.scrollWidth * -1, 0];
      gsap.fromTo(w, {  x  }, {
        x: xEnd,
        scrollTrigger: { 
          trigger: section, 
          scrub: 0.5 
        }
      });
    });    

    gsap.utils.toArray("#employee-card").forEach((section, index) => {
      gsap.to(section, {
        opacity: 1,
        ease: "none",
        scrollTrigger: {
          trigger: section,
          start: "top center",
          end: "bottom top-=" + `80vh`,
          onEnter: () => {
            gsap.to(section, {
              y: 0,
              duration: 1,
              opacity: 1,
              delay: 0.2*index
            });
          },
          onLeave: () => {
            gsap.to(section, {
              y: -100,
              duration: 1,
              opacity: 0,
              delay: 0.2*index
            });
          },
          onEnterBack: () => {
            gsap.to(section, {
              y: 0,
              duration: 1,
              opacity: 1,
              delay: 0.2*index
            });
          },
          onLeaveBack: () => {
            gsap.to(section, {
              y: -50,
              duration: 1,
              opacity: 0,
              delay: 0.2*index
            });
          }
        }
      })
    })

    const pulses = gsap.timeline({
      defaults: {
        duration: 0.05, 
        autoAlpha: 1, 
        scale: 2, 
        transformOrigin: 'center', 
        ease: "elastic(2.5, 1)"
      }})
    .to(".ball02, .text01", {}, 0.2) 
    .to(".ball03, .text02", {}, 0.33)
    .to(".ball04, .text03", {}, 0.46)
    .to(".ball05, .text04", {}, 0.59)
    .to(".ball06, .text05", {}, 0.72)
    // .to(".ball04, .text03", {}, 0.46)
    gsap.utils.toArray("#timeline-paragraph").forEach((section, index) => {
      gsap.to(section, {
        opacity: 1,
        ease: "none",
        scrollTrigger: {
          trigger: section,
          start: "center center+=" + `100px`,
          end: "bottom center-=" + `100px`,
          onEnter: () => {
            gsap.to(section, {
              y: 0,
              opacity: 1,
            })
          },
          onLeave: () => {
            gsap.to(section, {
              y: -100,
              duration: 1,
              opacity: 0,
            });
          },
          onEnterBack: () => {
            gsap.to(section, {
              y: 0,
              duration: 1,
              opacity: 1,
              delay: 0.2*index
            });
          },
          onLeaveBack: () => {
            gsap.to(section, {
              y: -50,
              duration: 1,
              opacity: 0,
              delay: 0.2*index
            });
          }
        }
      })
    })

    const main = gsap.timeline({
      defaults: {duration: 1},
      scrollTrigger: {
        trigger: "#svg",
        scrub: true,
        start: "top center",
        end: "bottom center"
      }})
    .to(".ball01", {duration: 0.01, autoAlpha: 1})
    .to(".ball01", {motionPath: {
      path: ".theLine", 
      align:".theLine",
      alignOrigin: [0.5, 0.5],
    }}, 0)
    .add(pulses, 0);
    
  }, {
    scope: main
  })
  
  return (
    <div ref={main} id="about-us">
      <section className="py-10 bg-primary">
        <div className="container mx-auto px-6">
          <div className="font-bold text-xl text-white">
            Бидний тухай
          </div>
        </div>
      </section>
      <div className="relative">
        <div className="container mx-auto py-10">
          <p id="title" className='text-2xl text-center font-bold text-gray-900'>Бидний түүх</p>
          <div className='relative hidden md:flex'>
              <svg id="svg" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 600 1200">
                <path className="line01 line" d="M 10 200  600 200" ></path>
                <path className="line02 line" d="M 10 400  600 400" ></path>
                <path className="line03 line" d="M 10 600  600 600" ></path>
                <path className="line04 line" d="M 10 800  600 800" ></path>
                <path className="line05 line" d="M 10 1000  600 1000" ></path>
                <text className="text01" x="30" y="190">1998</text>
                <text className="text02" x="30" y="390">2009</text>
                <text className="text03" x="30" y="590">2016-2017</text>
                <text className="text04" x="30" y="790">2023</text>
                <text className="text05" x="30" y="990">2024</text>

                <path className="theLine" 
                  d="M -5,0
                    Q 450 230 300 450 
                    T 130 750
                    Q 100 850 300 1000
                    T 150 1200"
                  fill="none" stroke="white" stroke-width="10px"/>
                
                
                
                <circle className="ball ball01" r="20" cx="50" cy="100"></circle>
                <circle className="ball ball02" r="20" cx="278" cy="201"></circle>
                <circle className="ball ball03" r="20" cx="327" cy="401"></circle>
                <circle className="ball ball04" r="20" cx="203" cy="601"></circle>
                <circle className="ball ball05" r="20" cx="152" cy="801"></circle>
                <circle className="ball ball06" r="20" cx="300" cy="1001"></circle>

              </svg>
              <div className="flex-1 md:relative absolute inset-0">
                <div className='flex-1 relative'>
                  <p id="timeline-paragraph" className="absolute top-[190px] pl-4 text-secondary font-medium opacity-0">Est deserunt mollit Lorem est non et.</p>
                  <p id="timeline-paragraph" className="absolute top-[390px] pl-4 text-secondary font-medium opacity-0">Nisi dolor id officia cupidatat adipisicing laborum proident incididunt ullamco proident aliquip qui.</p>
                  <p id="timeline-paragraph" className="absolute top-[590px] pl-4 text-secondary font-medium opacity-0">Magna sint enim non mollit Lorem sunt veniam eiusmod exercitation adipisicing nisi pariatur.</p>
                  <p id="timeline-paragraph" className="absolute top-[790px] pl-4 text-secondary font-medium opacity-0">Consequat et ad consequat occaecat anim minim velit laboris nulla fugiat non.</p>
                  <p id="timeline-paragraph" className="absolute top-[990px] pl-4 text-secondary font-medium opacity-0">Quis in laboris minim eu eiusmod magna eu.</p>
                </div>
              </div>
          </div>
        </div>
        <div className='bg-white rounded-lg p-6 md:hidden'>
          <p id="timeline-paragraph" className="pl-4 text-secondary font-medium mb-4">Lorem consectetur eiusmod cupidatat nisi enim tempor dolor labore nulla consectetur.</p>
          <p id="timeline-paragraph" className="pl-4 text-secondary font-medium mb-4">Consequat veniam culpa ad velit excepteur nostrud cillum.</p>
          <p id="timeline-paragraph" className="pl-4 text-secondary font-medium mb-4">Est culpa enim dolore excepteur aliquip in laboris cillum.</p>
          <p id="timeline-paragraph" className="pl-4 text-secondary font-medium mb-4">Anim et mollit veniam tempor ullamco.</p>
          <p id="timeline-paragraph" className="pl-4 text-secondary font-medium">Ullamco dolore consequat laboris id Lorem do duis est cupidatat anim.</p>
        </div>
      </div>
      <div className="py-10">
        <p id="title" className="text-3xl font-bold text-center text-secondary mb-10 text-gray-900">
          Бидний баг хамт олон
        </p>
        <div className="container mx-auto grid md:grid-cols-4 grid-cols-2 gap-4 px-6 md:px-0">
          <div id="employee-card" className="rounded overflow-hidden shadow opacity-0">
            <div className='relative'>
              <img fill="true" objectFit='cover' className="rounded w-full" src="https://via.placeholder.com/250">
              </img>
            </div>
            <div className="p-3">
              <p className='text-secondary text-xl font-bold text-center'>JOHN Doe</p>
              <p className="text-secondary text-md font-medium text-center">Chief executive officer</p>
            </div>
          </div>
          <div id="employee-card" className="rounded overflow-hidden shadow opacity-0">
            <div className='relative'>
              <img fill="true" objectFit='cover' className="rounded w-full" src="https://via.placeholder.com/250">
              </img>
            </div>
            <div className="p-3">
              <p className='text-secondary text-xl font-bold text-center'>JOHN Doe</p>
              <p className="text-secondary text-md font-medium text-center">Head of department</p>
            </div>
          </div>
          <div id="employee-card" className="rounded overflow-hidden shadow opacity-0">
            <div className='relative'>
              <img fill="true" objectFit='cover' className="rounded w-full" src="https://via.placeholder.com/250">
              </img>
            </div>
            <div className="p-3">
              <p className='text-secondary text-xl font-bold text-center'>JOHN Doe</p>
              <p className="text-secondary text-md font-medium text-center">Chief Operations Officer</p>
            </div>
          </div>
          <div id="employee-card" className="rounded overflow-hidden shadow opacity-0">
            <div className='relative'>
              <img fill="true" objectFit='cover' className="rounded w-full" src="https://via.placeholder.com/250">
              </img>
            </div>
            <div className="p-3">
              <p className='text-secondary text-xl font-bold text-center'>JOHN Doe</p>
              <p className="text-secondary text-md font-medium text-center">Head of department</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


export default AboutUs